.msg-wrapper {
    float: right;
    font-size: 12px;
    position: relative;
    margin-top: 5px;
}

.msg-wrapper.good {
    display: none;
}

.msg-wrapper .msg-error {
    display: none;
}

.msg-wrapper.error {
    color: #c32929;
}

.msg-wrapper.error .msg-error {
    display: block;
}

.msg-wrapper.error::after {
    width: 63px;
    height: 4px;
    content: '';
    display: block;
    background-color: #c32929;
    position: absolute;
    right: 12px;
    bottom: -9px;
    transform: skew(-45deg);
    box-shadow: 4px 4px 10px rgba(195, 41, 41, 0.7),
    -4px -4px 10px rgba(195, 41, 41, 0.7),
    -4px 4px 10px rgba(195, 41, 41, 0.7),
    4px -4px 10px rgba(195, 41, 41, 0.7);
}

label.input-label {
    font-size: 16px;
    color: #00edff;
    text-transform: uppercase;
    margin-top: 45px;
    display: block;
    width: 100%
}

input[type="text"], input[type="password"] {
    height: 52px;
    line-height: 52px;
    border-color: #00e7ff;
    background-color: rgba(4,35,41,0.65);
}

.error input[type="text"], .error input[type="password"],
input[type="text"].error, input[type="password"].error {
    border-color: #cc2b2b;
}


.error input[type="text"]:hover, .error input[type="password"]:hover,
input[type="text"].error:hover, input[type="password"].error:hover {
    border-color: #a72727;
}

.error input[type="text"]:focus, .error input[type="password"]:focus,
input[type="text"].error:focus, input[type="password"].error:focus {
    border-color: #871f1f;
}

input[type="text"], input[type="password"] {
    padding: 0 15px;
    transition-duration: 0.2s;
    font-size: 18px;
    color: #00e7ff;
}

input[type="text"]:hover, input[type="password"]:hover {
    border-color: #00838e;
}

input[type="text"]:focus, input[type="password"]:focus {
    border-color: #7af2ff;
}

.textarea-frame textarea {
    border: none !important;
    background: transparent;
    color: #00e7ff;
    display: block;
    min-height: 300px;
    resize: vertical;
    padding: 10px;
}

.textarea-frame textarea:focus {
    outline: none;
}

.textarea-frame.focused, .textarea-frame.focused * {
    border-color: #7af2ff !important;
}

.textarea-frame.error, .textarea-frame.error * {
    border-color: red !important;
}
