button.childBtn:focus {
    background: rgba(0,0,0,0.2);
    border-color: #7af2ff;
}

button.childBtn:hover {
    background: rgba(0,0,0,0.05);
    border-color: #00838e;
}
button.childBtn {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    background: transparent;
    border: 4px solid #00e7ff;
    color: white;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
}

button.childBtn.active:focus {
    background: #7af2ff;
}

button.childBtn.active:hover {
    background: #00838e;
}
button.childBtn.active {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    text-align: center;
    background: #00e7ff;
    color: black;
    font-size: 16px;
    margin-right: 10px;
    cursor: pointer;
}
