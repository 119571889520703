.m-l-15 {
    margin-left: 15px;
}

.m-t-15 {
    margin-top: 15px;
}

.pointer {
    cursor: pointer;
}

body {
    margin: 0;
    padding: 0;
}

.text-input {
    padding: .5rem;
    font-size: 16px;
    width: 100%;
    display: block;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.text-input:focus {
    border-color: #007eff;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 0 3px rgba(0, 126, 255, 0.1);
    outline: none;
}

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.error {
    color: red;
}

[class^="ArwesSounds(Button)-root"].full-width [class^="ArwesSounds(Button)"] {
    width: 100%;
}
